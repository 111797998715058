import React from 'react';
import { Box, Typography, Container, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next'; // Import useTranslation

const Program = () => {
  const { t } = useTranslation(); // Add translation hook

  return (
    <Box className="program_area black_bg" sx={{ padding: '40px 0', backgroundColor: '#222', color: '#fff' }}>
      <Container>
        {/* Neolongas Section */}
        <Typography variant="h3" className="section_title" sx={{ marginBottom: '40px', textAlign: 'center' }}>
          {t('neolongasTitle')}
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '40px', textAlign: 'center', maxWidth: '800px', margin: '0 auto' }}>
          {t('neolongasDescription')}
        </Typography>

        {/* Booking Section */}
        <Typography variant="h3" className="section_title" sx={{ marginBottom: '40px', textAlign: 'center' }}>
          {t('bookingTitle')}
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '20px', textAlign: 'center' }}>
          {t('bookingHurry')}
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '40px', textAlign: 'center' }}>
          {t('milongaPass')}: <strong>80€</strong>.<br />
          {t('entryGuarantee')}<br />
          {t('registerInstructions')}
        </Typography>

        {/* Program Section */}
        <Typography variant="h3" className="section_title" sx={{ marginBottom: '40px', textAlign: 'center' }}>
          {t('programTitle')}
        </Typography>

        <Grid container spacing={4} justifyContent="center">
          {[
            { time: '22.00-00.00', date: '18 April 2025', performer: 'DJ Raffaella' },
            { time: '00.00-02.00', date: '18 April 2025', performer: 'DJ Elio' },

            { time: '16.00-20.00', date: '19 April 2025', performer: 'DJ Ezequiel' },
            { time: '22.00-00.00', date: '19 April 2025', performer: 'DJ Elio' },
            { time: '00.00-02.00', date: '19 April 2025', performer: 'DJ Anna' },

            { time: '16.00-20.00', date: '20 April 2025', performer: 'DJ Frank' },
            { time: '22.00-00.00', date: '20 April 2025', performer: 'DJ Anna' },
            { time: '00.00-02.00', date: '20 April 2025', performer: 'DJ Ezequiel' },

            { time: '16.00-20.00', date: '21 April 2025', performer: 'DJ Elio' },
            { time: '22.00-00.00', date: '21 April 2025', performer: 'DJ Frank' },
            { time: '00.00-02.00', date: '21 April 2025', performer: 'DJ Raffaella' },
          ].map((event, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box sx={{ textAlign: 'center', backgroundColor: '#333', borderRadius: '8px', padding: '20px', marginBottom: '20px' }}>
                <Typography variant="h6">{event.time}</Typography>
                <Typography variant="body1">{event.date}</Typography>
                <Typography variant="body1"><strong>{event.performer}</strong></Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Program;
