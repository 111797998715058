import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { DataGrid } from '@mui/x-data-grid';
import { Typography, CircularProgress, Box, Paper, Card, CardContent, Grid } from '@mui/material';
import { format, parse } from 'date-fns';

const Dashboard = () => {
    const [registrations, setRegistrations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filterModel, setFilterModel] = useState({
      items: [
          {
              id: 1,
              field: 'email', // Campo su cui effettuare il filtro
              operator: 'contains', // Operatore
              value: '', // Valore di ricerca
          },
      ],
  });

  const formatDate = (dateString) => {
  
      try{

      const stringDate = dateString;
      const formatString = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"; // 'X' per l'offset timezone in date-fns
      
      // Parsing della stringa di data
      const parsedDate = parse(stringDate, formatString, new Date());
      
      // Formattazione della data nel formato desiderato, ad esempio 'dd/MM/yyyy'
      const formattedDate = format(parsedDate, 'dd/MM/yyyy');
      
      return formattedDate;
      }
      catch{
        return "";

      }
};


const formatACSI = (x) => {
  
  try{
    switch( x){
      case "ownCard":
        return "Ho già la tessera";
      case "needCard":
        return "Richiedo (Estero)";
      case "requestCard":
        return "Richiedo (Italia)";
      default:
        return ""
    }
  }
  catch{
    return "";

  }
};

const formatBool = (conf) => {
    try{
    
    if( conf.data[0]=="1"){
        return "Inviata";
        }else{
          return "";

        };
      }
  catch
  {return "";}
}





    useEffect(() => {
        const token = Cookies.get('token');

        if (!token) {
            setError('No token found. Please log in.');
            setLoading(false);
            return;
        }

        const fetchRegistrations = async () => {
            try {
                const response = await axios.post(
                    'https://sienatangomarathon.neotango.it/api-siena/registrazioni',
                    {}, 
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setRegistrations(response.data);
            } catch (err) {
                setError('Error fetching registrations.');
            } finally {
                setLoading(false);
            }
        };

        fetchRegistrations();
    }, []);

    if (loading) return <CircularProgress />;
    if (error) return <p>{error}</p>;

    const totalParticipants = registrations.length;
    const confirmedParticipants = registrations.filter(
      (reg) => formatBool(reg.confirmation_email_sent) === 'Inviata'
    ).length;
  
    const passCounts = registrations.reduce((acc, reg) => {
      acc[reg.type_name] = (acc[reg.type_name] || 0) + 1;
      return acc;
    }, {});

    const roleCounts = registrations.reduce((acc, reg) => {
      acc[reg.role] = (acc[reg.role] || 0) + 1;
      return acc;
    }, {});



    // Define columns with filterable headers
    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
         {
          field: 'created_at',
          headerName: 'Data registrazione',
          width: 150,
          valueFormatter: (params) => formatDate(params),
      }, 
      { field: 'email', headerName: 'Email', width: 150 },
      { field: 'phone_number', headerName: 'Telefono', width: 150 },  
      { field: 'full_name', headerName: 'Nominativo', width: 150 },
        { field: 'notes', headerName: 'Note', width: 150 },
        { field: 'role', headerName: 'Ruolo', width: 90 },
        { field: 'type_name', headerName: 'Pass', width: 150 },
        
        { field: 'country', headerName: 'Residenza', width: 100 },
        { field: 'dance_years', headerName: 'Anni di danze', width: 50 },
        { field: 'language', headerName: 'Lingua', width: 50 },
        
        
        
        

        { field: 'membership_option', headerName: 'ACSI', width: 150 ,
          valueFormatter: (x) => formatACSI(x), 
        },
        { field: 'country_birth', headerName: 'Nazionalità', width: 100 },
        { field: 'date_of_birth', headerName: 'Data Nascita',  width: 100,          valueFormatter: (x) => formatDate(x), },
        
        
        { field: 'fiscal_code', headerName: 'CF', width: 50 },
        { field: 'residence_city', headerName: 'Residenza', width: 50 },
        { field: 'cap', headerName: 'CAP', width: 50 },
        { field: 'street', headerName: 'Indirizzo', width: 50 },
        { field: 'paypal_mc_gross', headerName: 'Pagato', width: 90 },
        { field: 'saldo', headerName: 'Saldo', width: 90 },
        { field: 'paypal_fee', headerName: 'Commissioni', width: 90 },
        { field: 'confirmation_email_sent', headerName: 'Conferma', width: 90 ,valueFormatter: (conf) => formatBool(conf)},
        
    ];

    
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ padding: 2, marginBottom: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={2}>
            <Card sx={{ bgcolor: '#f5f5f5' }}>
              <CardContent>
                <Typography variant="h6">Totale Partecipanti</Typography>
                <Typography variant="h4" color="primary">{totalParticipants}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Card sx={{ bgcolor: '#e8f5e9' }}>
              <CardContent>
                <Typography variant="h6">Partecipanti Confermati</Typography>
                <Typography variant="h4" color="success.main">{confirmedParticipants}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ bgcolor: '#e3e5f5' }}>
              <CardContent>
                <Typography variant="h6">Partecipanti per Tipo di Pass</Typography>
                {Object.entries(passCounts).map(([passType, count]) => (
                  <Typography key={passType}>
                    <strong>{passType}</strong>: {count}
                  </Typography>
                ))}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Card sx={{ bgcolor: '#f3e5f5' }}>
              <CardContent>
                <Typography variant="h6">Partecipanti per Ruolo</Typography>
                {Object.entries(roleCounts).map(([passType, count]) => (
                  <Typography key={passType}>
                    <strong>{passType}</strong>: {count}
                  </Typography>
                ))}
              </CardContent>
            </Card>
          </Grid>
          
        </Grid>
      </Box>

      <DataGrid
  rows={registrations}
  columns={columns}
  filterModel={filterModel}
  disableMultipleColumnsFiltering
  getRowClassName={(params) => {
    switch (params.row.status) {
      case 0:
        return 'white-row';
      case 1:
        return 'yellow-row';
      case 4:
        return 'red-row';
      case 5:
        return 'green-row';
      default:
        return '';
    }
  }}
  sx={{
    boxShadow: 2,
    border: 1,
    borderColor: 'primary.light',
    '& .MuiDataGrid-cell:hover': {
      color: 'primary.main',
    },
    '& .white-row': {
      backgroundColor: '#FFFFFF',
    },
    '& .yellow-row': {
      backgroundColor: '#FFFF00',
    },
    '& .red-row': {
      backgroundColor: '#FF0000',
    },
    '& .green-row': {
      backgroundColor: '#80ff00',
    },
    '& .light-green-row': {
      backgroundColor: '#80ff00',
    },
  }}
/>

    </Box>
  );
};

export default Dashboard;