import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Box, IconButton,Menu, MenuItem, Modal, TextField, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MenuIcon from '@mui/icons-material/Menu';
import LanguageMenu from './components/LanguageMenu';
import MobileMenu from './components/MobileMenu';
import NavigationMenu from './components/NavigationMenu';
import Cookies from 'js-cookie';
import Home from './components/Home';
import About from './components/About';
import Performers from './components/Performers';
import Program from './components/Program';
import Registration from './components/Registration';
import Dashboard from './components/Dashboard';
import Payment from './components/Payment';



function App() {
  const { t } = useTranslation();
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [token, setToken] = useState(Cookies.get('token') || '');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

   // Open login modal on logo double-click
   const handleLogoDoubleClick = () => setLoginModalOpen(true);

   const handleLogin = async () => {
    try {
      const response = await axios.post('https://sienatangomarathon.neotango.it/api-siena/login', { username, password });
      if (response.data.success) {
        const tokenValue = response.data.token;
        Cookies.set('token', tokenValue, { expires: 7 });
        setToken(tokenValue);
        setLoginModalOpen(false);
      } else {
        alert('Login failed');
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('Login failed');
    }
  };
 
   // Handle logout (remove token from cookies and state)
   const handleLogout = () => {
     Cookies.remove('token');
     setToken('');
   };

   
  const handleMobileMenuOpen = (event) => {
    setMobileMenuAnchorEl(event.currentTarget); // Imposta il riferimento all'elemento corrente
  };

  const handleMenuClose = () => {
    setMobileMenuAnchorEl(null); // Chiudi il menu mobile
  };


  const [anchorEl, setAnchorEl] = useState(null);

  const handleAdminMenuOpen = (event) => {
    setAnchorEl(event.currentTarget); // Open menu at the icon's position
  };

  const handleAdminMenuClose = () => {
    setAnchorEl(null); // Close menu
  };


  return (
    <Router>
      <AppBar position="static" sx={{ backgroundColor: '#ffab00', color: '#000000' }}>
        <Toolbar>
        <img
            src="/img/logo.png"
            alt="Logo"
            onDoubleClick={handleLogoDoubleClick}
            style={{ width: 60, height: 60, marginRight: 16, borderRadius: '8px', cursor: 'pointer' }}
          />
      <Box sx={{ flexGrow: 1 }}>
  <Typography variant="h7" component="div">
    {t('evtDate')}
  </Typography>

  <Typography
    variant="h6"
    component="div"
    sx={{ fontWeight: 400, fontSize: '1rem', letterSpacing: '-0.5px' }} // Adjust font weight, size, and letter spacing
  >
    Siena Neotango Marathon
  </Typography>

  <Typography variant="h7" component="div">
    {t('evtMotto')}
  </Typography>
</Box>

          <LanguageMenu /> {/* Componente per la selezione della lingua */}

          {/* Pulsante hamburger per mobile */}
          <IconButton 
            color="inherit" 
            onClick={handleMobileMenuOpen} 
            sx={{ display: { xs: 'block', md: 'none' } }} // Mostra solo su schermi piccoli
          >
            <MenuIcon />
          </IconButton>

          <NavigationMenu />

          {token ? (

                    <>
                    {/* Icon button for admin settings */}
                    <IconButton onClick={handleAdminMenuOpen}  sx={{ 
                  backgroundColor: 'gold', // Set button background to yellow
                  color: 'black', // Set text color to black
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center', // Center the button text and icon
                  '&:hover': {
                    backgroundColor: 'yellow' // Change color on hover
                  }
                }} >
                      <AdminPanelSettingsIcon sx={{ color: 'blue' }} />
                    </IconButton>

                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleAdminMenuClose}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                      {/* Logout Menu Item */}
                   

                      {/* Link to Registrazioni Page as Menu Item */}
                      <MenuItem onClick={handleAdminMenuClose}>
                        <Link to="/dashboard" style={{ textDecoration: 'none', color: 'inherit' }}>
                          Pannello Registrazioni Maratona
                        </Link>
                      </MenuItem>

                       <MenuItem onClick={handleLogout}>Esci</MenuItem>

                    </Menu>

                    </>
          ) : null}

          {/* Menu mobile per la navigazione */}
          <MobileMenu anchorEl={mobileMenuAnchorEl} handleMenuClose={handleMenuClose} />
        </Toolbar>
      </AppBar>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/cancel" element={<Home />} />

        <Route path="/about" element={<About />} />
        <Route path="/performers" element={<Performers />} />
        <Route path="/program" element={<Program />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/dashboard" element={<Dashboard />} />
        </Routes>


          {/* Modal for login */}
      <Modal open={loginModalOpen} onClose={() => setLoginModalOpen(false)}>
        <Box sx={{ 
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
          width: 300, bgcolor: 'background.paper', p: 4, borderRadius: 2, boxShadow: 24 
        }}>
          <Typography variant="h6" component="h2">
            Login
          </Typography>
          <TextField
            fullWidth
            label="Username"
            variant="outlined"
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            fullWidth
            label="Password"
            type="password"
            variant="outlined"
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button variant="contained" fullWidth sx={{ mt: 2 }} onClick={handleLogin}>
            Login
          </Button>
        </Box>
      </Modal>
    </Router>
  );
}

export default App;
