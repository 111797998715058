import React from 'react';
import { Menu, MenuItem , Box,Typography} from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NavigationMenu = ({ anchorEl, handleMenuClose }) => {
  const open = Boolean(anchorEl);
  const { t } = useTranslation(); // Importa la funzione di traduzione

  return (
   


    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleMenuClose}
      PaperProps={{
        style: {
          backgroundColor: '#f0f0f0',
          border: '1px solid #ccc',
        },
      }}
    >
      <MenuItem component={Link} to="/" onClick={handleMenuClose}>
      {t('home')} {/* Traduzione per Home */}
      </MenuItem>
      <MenuItem component={Link} to="/performers" onClick={handleMenuClose}>
      {t('djs')} {/* Traduzione per DJs */}
      </MenuItem>
      <MenuItem component={Link} to="/program" onClick={handleMenuClose}>
      {t('program')} {/* Traduzione per Program */}
      </MenuItem>
      <MenuItem component={Link} to="/registration" onClick={handleMenuClose}>
      {t('registration')} {/* Traduzione per Registration */}
      </MenuItem>
      <MenuItem component={Link} to="/about" onClick={handleMenuClose}>
      {t('contacts')} {/* Traduzione per Contacts */}
      </MenuItem>

    </Menu>
 
  );
};

export default NavigationMenu;
