import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material/styles'; // Import ThemeProvider and createTheme
import './i18n'; // Import your i18n configuration

// Create a default theme
const theme = createTheme();

const container = document.getElementById('root');
const root = createRoot(container); // Usa createRoot per React 18

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}> {/* Wrap your App with ThemeProvider */}
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
