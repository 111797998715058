import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next) // Passa i18n a react-i18next
  .init({
    lng: 'en', // Lingua di default
    fallbackLng: 'en', // Lingua di riserva
    resources: {
      en: {
        translation: {
          ...require('./locales/translation.json').en,
          ...require('./locales/registrationTranslation.json').en,
          ...require('./locales/paymentTranslation.json').en,
        },
      },
      it: {
        translation: {
          ...require('./locales/translation.json').it,
          ...require('./locales/registrationTranslation.json').it,
          ...require('./locales/paymentTranslation.json').it,
        },
      },
      de: {
        translation: {
          ...require('./locales/translation.json').de,
          ...require('./locales/registrationTranslation.json').de,
          ...require('./locales/paymentTranslation.json').de,
        },
      },
      es: {
        translation: {
          ...require('./locales/translation.json').es,
          ...require('./locales/registrationTranslation.json').es,
          ...require('./locales/paymentTranslation.json').es,
        },
      },
      fr: {
        translation: {
          ...require('./locales/translation.json').fr,
          ...require('./locales/registrationTranslation.json').fr,
          ...require('./locales/paymentTranslation.json').fr,
        },
      },
      sv: {
        translation: {
          ...require('./locales/translation.json').sv, // Svedese
          ...require('./locales/registrationTranslation.json').sv,
          ...require('./locales/paymentTranslation.json').sv,
        },
      },
      cs: {
        translation: {
          ...require('./locales/translation.json').cs, // Ceco
          ...require('./locales/registrationTranslation.json').cs,
          ...require('./locales/paymentTranslation.json').cs,
        },
      },
      nl: {
        translation: {
          ...require('./locales/translation.json').nl, // Olandese
          ...require('./locales/registrationTranslation.json').nl,
          ...require('./locales/paymentTranslation.json').nl,
        },
      },
      ru: {
        translation: {
          ...require('./locales/translation.json').ru, // Russo
          ...require('./locales/registrationTranslation.json').ru,
          ...require('./locales/paymentTranslation.json').ru,
        },
      },
      tr: {
        translation: {
          ...require('./locales/translation.json').tr, // Turco
          ...require('./locales/registrationTranslation.json').tr,
          ...require('./locales/paymentTranslation.json').tr,
        },
      },
      ja: {
        translation: {
          ...require('./locales/translation.json').ja, // Giapponese
          ...require('./locales/registrationTranslation.json').ja,
          ...require('./locales/paymentTranslation.json').ja,
        },
      },
    },
    interpolation: {
      escapeValue: false, // React già fa l'escaping
    },
  });

export default i18n;
