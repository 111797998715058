import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  FormLabel,
  Autocomplete

} from '@mui/material';
import Swal from 'sweetalert2'; // Import SweetAlert
import { useTranslation } from 'react-i18next';

const Registration = () => {
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState({
    email: '',
    fullName: '',
    phoneNumber: '',
    role: '',
    passType: '',
    danceYears: '',
    countryId: '',
    membershipOption: '',
    acsiMemberData: '',
    countryOfBirthId: '',
    dateOfBirth: '',
    fiscalCode: '',
    residenceCity: '',
    cap: '',
    street: '',
    notes: '', 
    language: i18n.language, 
  });

  const [countries, setCountries] = useState([]);
  const [membershipCards, setMembershipCards] = useState([]);
  const [passTypes, setPassTypes] = useState([]);



  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://sienatangomarathon.neotango.it/api-siena/countries');
        const data = await response.json();
        setCountries(data);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    const fetchPassTypes = async () => {
      try {
        const response = await fetch('https://sienatangomarathon.neotango.it/api-siena/pass_types');
        const data = await response.json();
        setPassTypes(data);
      } catch (error) {
        console.error('Error fetching pass types:', error);
      }
    };

    fetchCountries();
    fetchPassTypes();
  }, []);


    // Update membershipCards whenever the language changes
    useEffect(() => {
      setMembershipCards([
        { id: 'ownCard', option_name: t('membershipOwnCard') },
        { id: 'needCard', option_name: t('membershipNeedCard') },
        { id: 'requestCard', option_name: t('membershipRequestCard') },
      ]);
    }, [t]); // Add t as a dependency

  // Aggiorna `formData.language` quando cambia la lingua corrente
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      language: i18n.language
    }));
  }, [i18n.language]);





  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'radio' ? value : e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    if (!formData.role) {
      Swal.fire({
        icon: 'warning',
        title: t('alertTitle'),
        text: t('warningRole'),
      });
      return;
    }

    if (!formData.countryId) {
      Swal.fire({
        icon: 'warning',
        title: t('alertTitle'),
        text: t('warningCountry'),
      });
      return;
    }

    if (!formData.membershipOption) {
      Swal.fire({
        icon: 'warning',
        title: t('alertTitle'),
        text: t('warningMembership'),
      });
      return;
    }

    if (formData.membershipOption === 'needCard' && !formData.countryOfBirthId) {
      Swal.fire({
        icon: 'warning',
        title: t('alertTitle'),
        text: t('warningBirthCountry'),
      });
      return;
    }
    const postData = {
      ...formData,
      language: i18n.language // Aggiorna la lingua alla versione più recente
    };


    try {
        const response = await fetch('https://sienatangomarathon.neotango.it/api-siena/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
        });

        const data = await response.json();

        Swal.fire({
            icon: response.ok ? 'success' : 'error',
            title: response.ok ? t('alertSuccess') : t('alertError'),
            text: data.message || t('alertDefaultError'),
        });

        if (response.ok) {
            setFormData({
                email: '',
                fullName: '',
                phoneNumber: '',
                role: '',
                passType: '',
                notes: '',
                danceYears: '',
                countryId: '',
                membershipOption: '',
                acsiMemberData: '',
                countryOfBirthId: '',
                dateOfBirth: '',
                fiscalCode: '',
                residenceCity: '',
                cap: '',
                street: '',
                language: i18n.language 
            });
        }
    } catch (error) {
        console.error('Error submitting the form:', error);
        Swal.fire({
            icon: 'error',
            title: t('alertError'),
            text: t('alertDefaultError'),
        });
    }
};

  return (
    <Box sx={{ padding: '40px', backgroundColor: '#f9f9f9' }}>
      <Typography variant="h4" gutterBottom>
        {t('pageTitle')}
      </Typography>
      <Typography variant="h5" gutterBottom>
        {t('registrationTitle')}
      </Typography>

      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label={t('email')}
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          sx={{ marginBottom: '20px' }}
        />
        <TextField
          fullWidth
          label={t('fullName')}
          name="fullName"
          value={formData.fullName}
          onChange={handleChange}
          required
          sx={{ marginBottom: '20px' }}
        />
        <TextField
          fullWidth
          label={t('phoneNumber')}
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          required
          sx={{ marginBottom: '20px' }}
/>

    <FormControl fullWidth required sx={{ marginBottom: '20px' }}>
      <FormLabel>{t('role')}</FormLabel>
      <RadioGroup name="role" value={formData.role} onChange={handleChange}>
        <FormControlLabel value="Leader" control={<Radio />} label={t('roleLeader')} />
        <FormControlLabel value="Follower" control={<Radio />} label={t('roleFollower')} />
        <FormControlLabel value="Leader and follower" control={<Radio />} label={t('roleLeaderAndFollower')} />
      </RadioGroup>
    </FormControl>


        <FormControl fullWidth required sx={{ marginBottom: '20px' }}>
          <FormLabel>{t('passType')}</FormLabel>
          <Select name="passType" value={formData.passType} onChange={handleChange}>
            {passTypes.map(pass => (
              <MenuItem key={pass.id} value={pass.id}>
                {pass.type_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth required sx={{ marginBottom: '20px' }}>
          <FormLabel>{t('country')}</FormLabel>
          <Autocomplete
            options={countries}
            getOptionLabel={(option) => option.country_name}
            onChange={(event, newValue) => {
              setFormData({ ...formData, countryId: newValue ? newValue.id : '' });
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </FormControl>

        <FormControl fullWidth required sx={{ marginBottom: '20px' }}>
          <FormLabel>{t('danceYears')}</FormLabel>
          <Select name="danceYears" value={formData.danceYears} onChange={handleChange}>
            <MenuItem value="1-2">1-2</MenuItem>
            <MenuItem value="3-6">3-6</MenuItem>
            <MenuItem value=">6">>6</MenuItem>
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label={t('notes')} // Localized label for notes
          name="notes"
          value={formData.notes}
          onChange={handleChange}
          multiline
          rows={3}
          sx={{ marginBottom: '20px' }}
        />

        <FormControl fullWidth required sx={{ marginBottom: '20px' }}>
          <FormLabel>{t('membershipOptions')}</FormLabel>
          <RadioGroup name="membershipOption" value={formData.membershipOption} onChange={handleChange}>
            {membershipCards.map(card => (
              <FormControlLabel key={card.id} value={card.id} control={<Radio />} label={card.option_name} />
            ))}
          </RadioGroup>
        </FormControl>

        {formData.membershipOption === "needCard" && (
          <>
            <FormControl fullWidth required sx={{ marginBottom: '20px' }}>
              <FormLabel>{t('countryOfBirth')}</FormLabel>
              <Autocomplete
                options={countries}
                getOptionLabel={(option) => option.country_name}
                onChange={(event, newValue) => {
                  setFormData({ ...formData, countryOfBirthId: newValue ? newValue.id : '' });
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </FormControl>
            <FormLabel>{t('dateOfBirth')}</FormLabel>
            <TextField
              fullWidth
              name="dateOfBirth"
              type="date"
              value={formData.dateOfBirth}
              onChange={handleChange}
              required
              sx={{ marginBottom: '20px' }}
            />
          </>
        )}

        {formData.membershipOption === "requestCard" && (
          <>
            <TextField
              fullWidth
              label={t('fiscalCode')}
              name="fiscalCode"
              value={formData.fiscalCode}
              onChange={handleChange}
              required
              sx={{ marginBottom: '20px' }}
            />
            <TextField
              fullWidth
              label={t('residenceCity')}
              name="residenceCity"
              value={formData.residenceCity}
              onChange={handleChange}
              required
              sx={{ marginBottom: '20px' }}
            />
            <TextField
              fullWidth
              label={t('cap')}
              name="cap"
              value={formData.cap}
              onChange={handleChange}
              required
              sx={{ marginBottom: '20px' }}
            />
            <TextField
              fullWidth
              label={t('street')}
              name="street"
              value={formData.street}
              onChange={handleChange}
              required
              sx={{ marginBottom: '20px' }}
            />
          </>
        )}

        <Button variant="contained" type="submit" color="primary">
          {t('submit')}
        </Button>

        <Typography variant="body2" sx={{ marginTop: '20px', textAlign: 'center' }}>
          {t('terms')}
        </Typography>
     
      </form>
    </Box>
  );
};

export default Registration;
