import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { Link } from 'react-router-dom'; // Import the Link component
import { ArrowForward } from '@mui/icons-material'; // Import an icon
import { useTranslation } from 'react-i18next';
import './Home.css'; // Make sure this file exists

const Home = () => {
  const { t } = useTranslation();

  return (
    <Box className="hero" sx={{ position: 'relative', overflow: 'hidden' }}>
      <img src="/img/hero.jpg" alt="Hero" className="hero-image" />
      <Box className="overlay" sx={{ 
          display: 'flex', 
          flexDirection: 'column', // Stack items vertically
          alignItems: 'center', 
          justifyContent: 'center', 
          height: '100vh', 
          textAlign: 'center', // Center text within the overlay
          color: 'white' // Set text color to white for better visibility over the image
        }}
      >
        <Container>
          <Typography variant="h4" gutterBottom className="wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
            {t('evtDate')}
          </Typography>
          <Typography variant="h4" gutterBottom className="wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
            {t('welcome')}
          </Typography>

          <Typography variant="body1" className="wow fadeInUp" data-wow-duration="1s" data-wow-delay=".8s">
            {t('homeMessage')}
          </Typography>
          <Typography variant="body1" sx={{ marginTop: '20px' }} className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s">
            {t('homeClosing')}
          </Typography>
          <Box sx={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
            <Link to="/registration" style={{ textDecoration: 'none' }}>
              <Button 
                variant="contained" 
                sx={{ 
                  backgroundColor: '#ffab00', // Set button background to yellow
                  color: 'black', // Set text color to black
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center', // Center the button text and icon
                  '&:hover': {
                    backgroundColor: 'darkorange' // Change color on hover
                  }
                }} 
                className="wow fadeInUp" 
                data-wow-duration="1s" 
                data-wow-delay="1.2s"
              >
                {t('joinUs')}
                <ArrowForward sx={{ marginLeft: '8px' }} /> {/* Add icon */}
              </Button>
            </Link>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Home;
