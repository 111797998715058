import React from 'react';
import { Menu, MenuItem, Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NavigationMenu = ({ anchorEl, handleMenuClose }) => {
  const open = Boolean(anchorEl);
  const { t } = useTranslation(); // Importa la funzione di traduzione

  return (
    <>
      {/* Menu di navigazione per desktop */}
      <Box sx={{ display: { xs: 'none', md: 'flex' }, marginLeft: 1 }}> {/* Mostra solo su schermi grandi */}
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Typography variant="button" sx={{ backgroundColor: '#8B4513', color: '#fff', padding: '8px 16px', borderRadius: '4px', marginLeft: 1 }}>
            {t('home')} {/* Traduzione per Home */}
          </Typography>
        </Link>

        <Link to="/performers" style={{ textDecoration: 'none' }}>
          <Typography variant="button" sx={{ backgroundColor: '#8B4513', color: '#fff', padding: '8px 16px', borderRadius: '4px', marginLeft: 1 }}>
            {t('djs')} {/* Traduzione per DJs */}
          </Typography>
        </Link>

        <Link to="/program" style={{ textDecoration: 'none' }}>
          <Typography variant="button" sx={{ backgroundColor: '#8B4513', color: '#fff', padding: '8px 16px', borderRadius: '4px', marginLeft: 1 }}>
            {t('program')} {/* Traduzione per Program */}
          </Typography>
        </Link>

        <Link to="/registration" style={{ textDecoration: 'none' }}>
          <Typography variant="button" sx={{ backgroundColor: '#8B4513', color: '#fff', padding: '8px 16px', borderRadius: '4px', marginLeft: 1 }}>
            {t('registration')} {/* Traduzione per Registration */}
          </Typography>
        </Link>

        <Link to="/about" style={{ textDecoration: 'none' }}>
          <Typography variant="button" sx={{ backgroundColor: '#8B4513', color: '#fff', padding: '8px 16px', borderRadius: '4px', marginLeft: 1 }}>
            {t('contacts')} {/* Traduzione per Contacts */}
          </Typography>
        </Link>
      </Box>
    </>
  );
};

export default NavigationMenu;
