import React, { useState } from 'react';
import { Button, Menu, MenuItem, Box, useMediaQuery } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import EnglishIcon from '@mui/icons-material/Language'; // Puoi sostituire con un'icona della bandiera
import ItalianIcon from '@mui/icons-material/Language'; // Icona per la bandiera italiana
import GermanIcon from '@mui/icons-material/Language'; // Puoi sostituire con un'icona della bandiera
import SpanishIcon from '@mui/icons-material/Language'; // Puoi sostituire con un'icona della bandiera
import FrenchIcon from '@mui/icons-material/Language'; // Puoi sostituire con un'icona della bandiera
import SwedishIcon from '@mui/icons-material/Language'; // Puoi sostituire con un'icona della bandiera svedese
import CzechIcon from '@mui/icons-material/Language'; // Puoi sostituire con un'icona della bandiera ceca
import DutchIcon from '@mui/icons-material/Language'; // Puoi sostituire con un'icona della bandiera olandese
import RussianIcon from '@mui/icons-material/Language'; // Puoi sostituire con un'icona della bandiera russa
import TurkishIcon from '@mui/icons-material/Language'; // Puoi sostituire con un'icona della bandiera turca
import JapaneseIcon from '@mui/icons-material/Language'; // Icona per il giapponese

import { useTranslation } from 'react-i18next';

const LanguageMenu = () => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm')); // Verifica se lo schermo è piccolo

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleMenuClose();
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{ backgroundColor: '#8B4513', color: '#fff', marginLeft: 1 }}
        onClick={handleMenuClick}
        startIcon={<LanguageIcon sx={{ color: '#fff' }} />}
      >
        {!isSmallScreen && t('language')} {/* Mostra testo solo su schermi più grandi */}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            backgroundColor: '#f0f0f0',
            border: '1px solid #ccc',
          },
        }}
      >
        <MenuItem onClick={() => changeLanguage('en')}>
          <EnglishIcon sx={{ color: '#3f51b5', marginRight: 1 }} /> {/* Bandiera inglese */}
          {'English'}
        </MenuItem>
        <MenuItem onClick={() => changeLanguage('it')}>
          <ItalianIcon sx={{ color: '#3946e6', marginRight: 1 }} /> {/* Bandiera italiana */}
          {'Italiano'}
        </MenuItem>
        <MenuItem onClick={() => changeLanguage('de')}>
          <GermanIcon sx={{ color: '#ffcc00', marginRight: 1 }} /> {/* Bandiera tedesca */}
          {'Deutsch'}
        </MenuItem>
        <MenuItem onClick={() => changeLanguage('es')}>
          <SpanishIcon sx={{ color: '#ff5733', marginRight: 1 }} /> {/* Bandiera spagnola */}
          {'Español'}
        </MenuItem>
        <MenuItem onClick={() => changeLanguage('fr')}>
          <FrenchIcon sx={{ color: '#4639e6', marginRight: 1 }} /> {/* Bandiera francese */}
          {'Français'}
        </MenuItem>
        <MenuItem onClick={() => changeLanguage('sv')}>
          <SwedishIcon sx={{ color: '#005cbf', marginRight: 1 }} /> {/* Bandiera svedese */}
          {'Svenska'}
        </MenuItem>
        <MenuItem onClick={() => changeLanguage('cs')}>
          <CzechIcon sx={{ color: '#d52b1e', marginRight: 1 }} /> {/* Bandiera ceca */}
          {'Čeština'}
        </MenuItem>
        <MenuItem onClick={() => changeLanguage('nl')}>
          <DutchIcon sx={{ color: '#21468b', marginRight: 1 }} /> {/* Bandiera olandese */}
          {'Nederlands'}
        </MenuItem>
        <MenuItem onClick={() => changeLanguage('ru')}>
          <RussianIcon sx={{ color: '#a50034', marginRight: 1 }} /> {/* Bandiera russa */}
          {'Русский'}
        </MenuItem>
        <MenuItem onClick={() => changeLanguage('tr')}>
          <TurkishIcon sx={{ color: '#e63946', marginRight: 1 }} /> {/* Bandiera turca */}
          {'Türkçe'}
        </MenuItem>
        <MenuItem onClick={() => changeLanguage('ja')}>
          <JapaneseIcon sx={{ color: '#000', marginRight: 1 }} /> 日本語
        </MenuItem>
      </Menu>
    </>
  );
};

export default LanguageMenu;
