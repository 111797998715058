import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Box, Card, CardContent, Typography, Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const Payment = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const { t, i18n } = useTranslation();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Funzione per recuperare i dati utente e l'importo da pagare
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://sienatangomarathon.neotango.it/api-siena/payment-info?token=${token}`);
        setUserData(response.data);
        if (response.data.success && response.data.data.language) {
            i18n.changeLanguage(response.data.data.language);
        }
      } catch (error) {
        console.error('Errore nel recupero dei dati:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [token]);


  if (loading) {
    return (
      <Container sx={{ textAlign: 'center', padding: '40px' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container sx={{ paddingTop: '40px' }}>
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <Card sx={{ maxWidth: 600, padding: '20px', backgroundColor: '#f5f5f5' }}>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              {t('paymentTitle')}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t('paymentGreeting', { name: userData.data.name })}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {t('paymentInstructions')}
            </Typography>
            <Typography variant="h6" color="primary">
              {t('amountDue')}: {userData.data.amount}
            </Typography>

            <Box mt={4} textAlign="center">
            <form action="https://www.paypal.com/donate" method="post" target="_top">
                <input type="hidden" name="hosted_button_id" value="G4E535JK7VTC8" />
                <input type="image" src="https://www.paypalobjects.com/en_US/IT/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                <img alt="" border="0" src="https://www.paypal.com/en_IT/i/scr/pixel.gif" width="1" height="1" />
                <input type="hidden" name="notify_url" value="https://sienatangomarathon.neotango.it/api-siena/notify" />
                <input type="hidden" name="item_name" value={userData.data.type_name} />
                <input type="hidden" name="custom" value={userData.data.id}  />
            </form>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default Payment;
