import React from 'react';
import { Typography, Container, Box, Card, CardContent, CardMedia, Link } from '@mui/material';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';
import '../i18n';

// Icona personalizzata per la mappa
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const About = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer" style={{ backgroundColor: '#333', color: '#fff', padding: '40px 0' }}>
      <Container>
        <Box display="flex" justifyContent="center" gap={4} flexWrap="wrap">
          
              {/* Mappa della location */}
        <Box mt={4}>
          
          <Box sx={{ height: 400, width: '100%', marginTop: 2 }}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2897.8949666419244!2d11.13646857729883!3d43.421021167414814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132a3b82644ad67f%3A0x6722f6e7a15b024b!2sSportland%20la%20badia!5e0!3m2!1sen!2sit!4v1730432049131!5m2!1sen!2sit" width="800" height="350"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </Box>
        </Box>
          
          
          {/* Location Card */}
          <Card sx={{ maxWidth: 345, backgroundColor: '#444', color: '#fff' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>{t('contactLocation')}</Typography>
              <Typography variant="body2">{t('contactAddress1')}</Typography>
              <Typography variant="body2">{t('contactAddress2')}</Typography>
            </CardContent>
          </Card>

          {/* Informazioni Card */}
          <Card sx={{ maxWidth: 345, backgroundColor: '#444', color: '#fff' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>{t('contactInfo')}</Typography>
              <Typography variant="body2">{t('contactAssociation')}</Typography>
              <Typography variant="body2">{t('contactPhone')}</Typography>
            </CardContent>
          </Card>

          {/* Email Card */}
          <Card sx={{ maxWidth: 345, backgroundColor: '#444', color: '#fff' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>{t('contactEmail')}</Typography>
              <Typography variant="body2">{t('contactEmailAddress')}</Typography>
            </CardContent>
          </Card>
        </Box>

    

        {/* Social Links Section */}
        <Box mt={4} display="flex" justifyContent="center" gap={4} flexWrap="wrap">
          {/* Carpe Diem Card */}
          <Card sx={{ maxWidth: 300, textAlign: 'center', backgroundColor: '#444' }}>
            <Link href="https://www.facebook.com/profile.php?id=100067410403751" target="_blank" rel="noopener noreferrer">
              <CardMedia
                component="img"
                height="140"
                image="img/carpediem.jpg"
                alt={t('linkCarpeDiem')}
                sx={{ objectFit: 'contain' }}
              />
              <CardContent>
                <Typography variant="h6" color="#fff">{t('linkCarpeDiem')}</Typography>
              </CardContent>
            </Link>
          </Card>

          {/* Neotango Roma Card */}
          <Card sx={{ maxWidth: 300, textAlign: 'center', backgroundColor: '#444' }}>
            <Link href="http://www.neotango.it" target="_blank" rel="noopener noreferrer">
              <CardMedia
                component="img"
                height="140"
                image="img/logoanellop.png"
                alt={t('linkNeotango')}
                sx={{ objectFit: 'contain' }}
              />
              <CardContent>
                <Typography variant="h6" color="#fff">{t('linkNeotango')}</Typography>
              </CardContent>
            </Link>
          </Card>
        </Box>
      </Container>
    </footer>
  );
};

export default About;
