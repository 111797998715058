import React from 'react';
import { Box, Typography, Grid, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './Performers.css'; // Assicurati di avere un file CSS per gli stili

const Performers = () => {
  const { t } = useTranslation();

  return (
    <Box className="performer_area" sx={{ padding: '40px 0', backgroundColor: '#222', color: '#fff' }}>
      <Container>
        {/* DJs Section */}
        <Box sx={{ marginBottom: '80px' }}>
          <Typography variant="h3" className="text-center wow fadeInRight" data-wow-duration="1s" data-wow-delay=".3s" sx={{ marginBottom: '40px' }}>
          {t('confirmedDJs')}
          </Typography>
          
          <Grid container spacing={4} justifyContent="center">
            {[
              { name: "Anna Neum", location: "Russia/France (FR)", image: "img/performer/anna.jpg", bioKey: "anna" },
              { name: "Elio Astor", location: "Rome (IT)", image: "img/performer/ea.jpg", bioKey: "elio" },
              { name: "Ezequiel Sanucci", location: "Amsterdam (NL)", image: "img/performer/ez.jpg", bioKey: "ezequiel" },
              { name: "Frank", location: "Florence (IT)", image: "img/performer/Frank.png", bioKey: "frank" },           
              { name: "Raffaella", location: "Paris (FR)", image: "img/performer/Raffaella.jpg", bioKey: "raffaella" },
            ].map((performer, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box className="single_performer wow fadeInUp" data-wow-duration="1s" data-wow-delay={`${0.3 + index * 0.1}s`} sx={{ textAlign: 'center', position: 'relative' }}>
                  <Box className="thumb performer-card">
                    <img src={performer.image} alt={performer.name} style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />
                    <Box className="bio-overlay">
                      <Typography variant="body2" sx={{ color: '#fff', padding: '10px' }}>{t(`djBio.${performer.bioKey}`)}</Typography>
                    </Box>
                  </Box>
                  <Box className="performer_heading" sx={{ marginTop: '15px' }}>
                    <Typography variant="h5">{performer.name}</Typography>
                    <Typography variant="body2" sx={{ color: '#bbb' }}>{performer.location}</Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Performers;
